<template>
    <div class="social-meeting-post-detail" :class="{ considerInterestedButton: showBottomButton }" @scroll="onScroll">
        <StackRouterHeaderBar :title="post.title" :class="{ 'show-title': showTitle }" :showTitle="showTitle" />
        <div class="post-detail-photo">
            <img v-if="photoURL" class="background" v-lazy="photoURL" />
            <div v-else class="empty-photo">
                <div class="empty-member">
                    {{
                        isGroupEvent
                            ? `회원끼리 (${post.member}명)`
                            : `친구끼리 (${post.member / 2}:${post.member / 2})`
                    }}
                </div>
                <div class="empty-title m-t-14">
                    {{ post.title }}
                </div>
            </div>
        </div>
        <div class="post-detail-base" :class="{ 'more-padding': showBottomButton }">
            <div class="post-detail-content">
                <div class="due-created flex-row flex-between f-13 padding-horizon">
                    <p class="category c-facebook f-bold">
                        {{
                            isGroupEvent
                                ? `회원끼리 (${post.member}명)`
                                : `친구끼리 (${post.member / 2}:${post.member / 2})`
                        }}
                    </p>
                    <p class="created c-text-lighter f-regular">{{ postedAt }}</p>
                </div>
                <div class="group-title padding-horizon m-t-12">
                    <p class="title m-r-8 lines-1">{{ post.title }}</p>
                    <span class="f-14" v-if="isGroupEvent">
                        {{ `${!post.chat_users ? post.chat_users + 1 : post.chat_users}/${post.member}` }}
                    </span>
                </div>

                <div v-if="!isGroupEvent" class="anonymous-host padding-horizon" @click="clickProfile">
                    <div class="gender-photo" :class="[post.host_info.gender === 0 ? 'bg-male' : 'bg-female']">
                        <img class="blind-photo" v-if="isBlindProfile" :src="require('@/assets/images/person.svg')" />
                        <img class="mini-photo" v-else :src="host.primary_photo" alt="" />
                    </div>
                    <div class="host items-center m-l-8">
                        <span class="nickname">{{ post.host_info.nickname }} ({{ hostGender }})</span>
                        <span class="m-l-4 f-13 age-dot">• {{ realOrBlindAge }}</span>
                        <div class="verify" v-if="badgeTitle">
                            <span class="verify-info">{{ badgeTitle }}</span>
                            <span>인증 완료</span>
                            <i class="material-icons">admin_panel_settings</i>
                        </div>
                    </div>
                </div>

                <div v-if="isGroupEvent" @click="goHostDetailPage">
                    <div class="verified-host padding-horizon">
                        <img style="width: 56px; height: 56px; border-radius: 23px" :src="host.primary_photo" alt="" />
                        <div class="host items-center m-l-8">
                            <span class="nickname"
                                >호스트 <span class="f-bold">{{ host.nickname }}</span></span
                            >
                            <span class="m-l-4 f-13 age-dot"
                                >• {{ !host.gender ? '남,' : '여,' }} {{ realOrBlindAge }}</span
                            >
                            <div class="verify" v-if="badgeTitle">
                                <span class="verify-info">{{ badgeTitle }}</span>
                                <span>인증 완료</span>
                                <i class="material-icons">admin_panel_settings</i>
                            </div>
                        </div>
                    </div>
                    <div v-if="host.introduce" class="intro-container">
                        <div class="host-intro">
                            {{ host.introduce }}
                        </div>
                    </div>
                </div>

                <div class="meeting-infos">
                    <div class="info">
                        <img class="m-r-24" :src="require('@/assets/images/social-meeting/date-range-24-px.svg')" />
                        <span class="c-black f-regular">{{ dateAndHowLong }}</span>
                    </div>
                    <div class="info">
                        <img class="m-r-24" :src="require('@/assets/images/social-meeting/room-24-px-3.svg')" />
                        <span class="c-black f-regular">{{ getEventRegion }}</span>
                    </div>
                    <div class="info m-b-30">
                        <img
                            class="m-r-24"
                            :src="require('@/assets/images/social-meeting/supervised-user-circle-24-px-1.svg')"
                        />
                        <span class="c-black f-regular">{{ post.category }}</span>
                    </div>
                </div>
                <div class="content">
                    <p class="desc" :class="[post.place_name ? 'm-b-32' : '']" v-html="content" />
                    <div v-show="post.place_name">
                        <div class="map-container">
                            <div ref="map" class="map-img" />
                            <div class="placeinfo">
                                <div class="placename" v-html="post.place_name" />
                                <div class="address" v-html="post.place_address" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="post-detail-participants">
                <div class="group-event m-b-28" v-if="isGroupEvent && isHost">
                    <div class="title-wrapper m-b-24">
                        <p class="title" v-html="'신청한 회원'" />
                        <div
                            @click="inviteParticipants"
                            class="invite-btn"
                            :class="{ activate: activate && activateCheckBox }"
                            v-if="post.participants.length"
                            v-html="activateCheckBox ? $translate('INVITE') : $translate('INVITE_END')"
                        />
                    </div>
                    <div v-if="post.participants.length">
                        <SocialMeetingCardForHost
                            v-for="card in interestedCards"
                            :activateCheckBox="activateCheckBox"
                            :card="card"
                            :me="'host'"
                            :key="card.id"
                            @toggle="submitToggleInfo"
                        />
                    </div>
                    <div v-else class="no-cards">아직 참가 신청한 분이 없어요</div>
                </div>
                <div class="friend-event" v-if="!isGroupEvent">
                    <div v-if="showHostCard" class="m-b-34">
                        <p class="title m-b-24" v-html="$translate('HOST_PROFILE')" />
                        <div class="cards">
                            <SocialMeetingCardForFriend v-if="hostCard" :card="hostCard" :me="'participant'" />
                        </div>
                    </div>
                    <div v-if="showParticipantCard" class="m-b-28">
                        <div class="title-wrapper m-b-24">
                            <p class="title" v-html="`관심있는 회원`" />
                        </div>
                        <div class="cards">
                            <SocialMeetingCardForFriend
                                v-for="card in interestedCards"
                                :card="card"
                                :me="`host`"
                                :key="card.id"
                            />
                        </div>
                        <span class="no-cards" v-if="interestedCards.length === 0">아직 참가 신청한 분이 없어요</span>
                    </div>
                </div>
            </div>
            <div class="social-meeting-manual">
                <p class="f-16 c-black">{{ $translate('SOCIAL_MEETING_MANUAL') }}</p>

                <ul>
                    <li class="manual" v-for="m in manual" :key="m">
                        <div class="dot" />
                        <p class="text" v-html="m" />
                    </li>
                </ul>
            </div>
        </div>
        <BottomButton
            v-if="showBottomButton"
            :disabled="disabled"
            :label="bottomButtonLabel"
            :service="setCtaColor"
            @click="onClickBtn"
        />
    </div>
</template>
<script>
import SocialMeetingCardForHost from '@/routes/social-meeting/components/SocialMeetingCardForHost'
import SocialMeetingCardForFriend from '@/routes/social-meeting/components/SocialMeetingCardForFriend'
import eventService from '@/services/event'
import badgeService from '@/services/verification-badge'

export default {
    name: 'SocialMeetingPostDetailPage',
    components: {
        SocialMeetingCardForHost,
        SocialMeetingCardForFriend,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        from: {
            type: String,
        },
    },
    data: () => ({
        showTitle: false,
        disabled: false,
        allBadges: [],
        summaryBadges: [],
        badgeTitle: '',
        hostProfile: '',
        dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
        map: '',
        inviteList: [],
        chatId: null,
    }),
    created() {
        this.disabled = this.dateAndCategory === '마감' || !!this.post.participant
        this.loadHostBadges()
    },
    mounted() {
        this.chatId = this.post.chat_id
        this.initMap()
    },
    computed: {
        host() {
            return this.post.host_info
        },
        isHost() {
            return this.host.id === this.$store.getters.me.id
        },
        isGroupEvent() {
            return this.post.event_type === 'group_meeting'
        },
        getEventRegion() {
            const region =
                this.$store.getters.eventRegions.find(region => region.id === this.post.event_region_id) || {}
            const seoulOrKyungKi = [1, 2].includes(region.state_id)
            return `${this.post.state}${seoulOrKyungKi ? `(${region.name})` : ''}`
        },
        isBlindProfile() {
            return this.post.profile_style === 'privacy'
        },
        realOrBlindAge() {
            const age = this.post.host_info.age

            if (this.isBlindProfile) return this.$options.filters.blindAge(age)
            else return `${age}세`
        },
        headerTitle() {
            return this.post.title
        },
        content() {
            return this.post.content.split(/\n/).join('<br>')
        },
        showBottomButton() {
            // 내가 작성한 글 관심있어요 버튼이 보이면 안됨
            return this.activateHostBtn || this.post.host_info.id !== this.$store.getters.me.id
        },
        showParticipantCard() {
            return this.post.participants || false
        },
        showHostCard() {
            return !this.isGroupEvent && this.post.participant
        },
        hostGender() {
            const gender = this.$options.filters.gender(this.post.host_info.gender)
            return this.$translate(gender)[0]
        },
        dateAndCategory() {
            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')
            let expireAtToString

            if (diff < 0) {
                return '마감'
            } else {
                if (diff === 0) expireAtToString = '오늘 만나요'
                if (diff === 1) expireAtToString = '내일 만나요'

                const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토']

                return `${expireAtToString ? expireAtToString : ''} (${expireAt.format('MM.DD')} ${
                    dayOfWeek[expireAt.day()]
                })`
            }
        },
        dateAndHowLong() {
            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')

            let expireAtToString
            if (diff < 0) return '마감'
            else {
                if (diff === 0) expireAtToString = '오늘'
                if (diff === 1) expireAtToString = '내일'

                const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토']
                return `${expireAt.format('MM.DD')} ${dayOfWeek[expireAt.day()]} ${
                    expireAtToString ? `(${expireAtToString})` : ''
                }`
            }
        },
        postedAt() {
            const days = this.$moment().diff(this.post.created_at, 'days')
            const hours = this.$moment().diff(this.post.created_at, 'hours')
            const mins = this.$moment().diff(this.post.created_at, 'minutes')

            if (days) {
                return `${days}일 전 게시`
            } else if (hours) {
                return `${hours}시간 전 게시`
            } else {
                return `${mins}분 전 게시`
            }
        },
        showCardDue() {
            return this.dateAndCategory === '마감'
                ? this.dateAndCategory
                : this.dateAndCategory.slice(0, 2) + ' 자정까지만 확인할 수 있어요!'
        },
        hostCard() {
            if (this.post.participant) {
                const hostInfo = { host_info: { ...this.post.host_info } }
                return { ...this.post.participant, ...hostInfo }
            }

            return {}
        },
        interestedCards() {
            return this.post.participants || []
        },
        bottomButtonLabel() {
            if (this.dateAndCategory === '마감') return '마감'
            if (this.post.participant && this.isGroupEvent) return '참가 신청 완료'
            if (this.activateHostBtn) {
                if (!this.post.chat_users) return '모임 취소'
                else return '단톡방 이동'
            }
            if (this.isGroupEvent && !this.activateHostBtn) return this.$translate('SHOW_INTEREST_GROUP')

            return this.$translate('SHOW_INTEREST_FRIEND')
        },
        photoURL() {
            if (!this.post.photo_url) return null

            if (this.post.photo_url.includes('medium')) {
                return this.post.photo_url.replace(/medium/, 'original')
            }
            return this.post.photo_url
        },
        keywords() {
            let dateStr = ''
            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')
            const month = (expireAt.month() + 1) % 13
            const date = expireAt.date()
            const day = expireAt.day()

            if (diff < 0) {
                dateStr = '마감'
            } else if (diff === 0) {
                dateStr = `${month}.${date} (오늘)`
            } else if (diff === 1) {
                dateStr = `${month}.${date} (내일)`
            } else {
                dateStr = `${month}.${date} (${this.dayOfWeek[day]})`
            }

            if (this.post.event_type === 'group_meeting') {
                return [dateStr, this.post.state, this.post.category]
            } else {
                return [dateStr, this.post.state]
            }
        },
        activate() {
            // 톡방 생성 전에는 호스트 포함 3인이 되어야 단톡방 초대 버튼 활성화
            if (!this.post.chat_users) {
                return this.inviteList.length >= 2
            }
            return this.inviteList.length
        },
        activateCheckBox() {
            return this.post.member - this.post.chat_users > 0
        },
        activateHostBtn() {
            return this.isGroupEvent && this.isHost
        },
        setCtaColor() {
            if (this.activateHostBtn) {
                if (this.post.chat_users) return 'socialMeet'
                return 'cancel_hostmeet'
            }
            return 'socialMeet'
        },
        canInvite() {
            if (!this.inviteList.length) return false

            // 초대하려는 사람의 수가 허용 가능 인원 수보다 적어야 초대 가능
            if (!this.post.chat_users) return this.post.member - 1 >= this.inviteList.length
            else return this.post.member - this.post.chat_users >= this.inviteList.length
        },
        manual() {
            if (this.post.event_type === 'group_meeting') {
                const diffText = this.isHost
                    ? `<span>단톡방</span>을 오픈할 수 있어요.`
                    : `호스트가 <span>단톡방</span>을 오픈합니다.`
                return [
                    `참여 회원이 <span>3명 이상</span>이 되면(본인 포함), ${diffText} 단톡방에서 간단히 인사를 나누고 즐거운 만남을 가져보세요!`,
                ]
            } else {
                const diffText = this.isHost
                    ? `<span>관심수락</span>을 하면, 상대가 내 프로필을 확인할 수 있어요.`
                    : `관심을 보낸 후, 호스트가 <span>관심 수락</span>을 하면 호스트의 프로필을 확인할 수 있어요.`
                return [
                    '나와 내 친구 + 회원과 회원의 친구를 N:N으로 만나는 모임입니다.',
                    diffText,
                    '프로필을 본 후, 양쪽이 모두 <span>만남 수락</span>을 하면 연락처가 교환돼요.',
                ]
            }
        },
    },
    methods: {
        initMap() {
            if (this.post.place_name) {
                const mapContainer = this.$refs.map
                const { position } = this.post
                const center = new kakao.maps.LatLng(position.lng, position.lat)
                const mapOption = {
                    center: center,
                    lever: 3,
                    disableDoubleClick: true,
                }
                this.map = new kakao.maps.Map(mapContainer, mapOption)
                kakao.maps.event.preventMap(this.map)
                var marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(position.lng, position.lat),
                })
                marker.setMap(this.map)
            }
        },
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        onClickBtn() {
            if (this.activateHostBtn) this.onClickHostBtn()
            else this.onClickInterestButton()
        },
        async onClickHostBtn() {
            try {
                if (this.post.chat_users) {
                    // 채팅방이 있는 경우(chat_users의 수로 판단) 채팅방으로 이동
                    this.$stackRouter.pop()
                    this.goChatRoom(this.chatId)
                } else {
                    // 채팅방이 없는 경우 모임 취소 가능
                    const idx = await this.$modal.basic({
                        body: '(모임 취소) 모임을 취소하시겠습니까? 취소할 경우, 신청 회원에게 취소 알림이 전송됩니다.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        await eventService.cancelGroupMeet(this.post.id)
                        this.$toast.success('모임을 취소했어요')
                        this.$store.commit('updateRemovedPost', this.post.id)
                        this.$stackRouter.pop()
                    }
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        onClickInterestButton() {
            const { vanilla: currentVanilla } = this.$store.getters.badges
            const { vanilla } = this.$store.getters.settings

            if (currentVanilla < vanilla.participate_meeting) {
                this.$modal
                    .basic({
                        body: `바닐라 ${vanilla.participate_meeting - currentVanilla}개가 부족합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({ name: 'InAppStorePage' })
                        }
                    })
            } else {
                const comment = this.isGroupEvent ? '참가 신청합니다.' : '관심을 보냅니다.'
                const fbCategory = this.isGroupEvent
                    ? 'SocialGroupPost_Click_GiveLikeBtn'
                    : 'SocialFriendsPost_Click_GiveLikeBtn'
                this.$modal
                    .basic({
                        body: `바닐라 ${vanilla.participate_meeting}개를 사용해 ${comment}`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$nativeBridge.postMessage({
                                action: 'sendFirebaseEvent',
                                value: {
                                    category: fbCategory,
                                },
                            })
                            this.requestInteresting()
                            this.disabled = true
                        }
                    })
            }
        },
        async requestInteresting() {
            try {
                this.$loading(true)
                const { event_participant: participants, msg } = await eventService.request(this.post.id)
                this.$store.commit('updateSocialClickInterest', participants)
                this.$store.dispatch('loadBadges')
                this.$toast.success(msg)
            } catch (e) {
                if (e.data.key === 'already_meet') {
                    this.$toast.error(e.data.msg)
                } else {
                    this.$toast.error('호스트가 모임을 취소했어요')
                    this.$store.commit('updateRemovedPost', this.post.id)
                    this.$stackRouter.pop()
                }
            } finally {
                this.$loading(false)
            }
        },
        showToast() {
            if (this.showCardDue === '마감') {
                const msg = '휴면/탈퇴한 회원의 프로필은 사라져요'
                this.$toast.success(msg)
            } else {
                const msg = this.showCardDue + '\n' + '휴면/탈퇴한 회원의 프로필은 사라져요'
                this.$toast.success(msg)
            }
        },
        clickProfile() {
            const fbCategory = this.isGroupEvent
                ? 'SocialGroupPost_Click_HostProfile'
                : 'SocialFriendPost_Click_HostProfile'

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: fbCategory,
                },
            })

            this.$modal.custom({
                component: 'ModalProfileView',
                options: {
                    profileStyle: this.post.profile_style,
                    hostInfo: this.post.host_info,
                    badgeTitle: this.badgeTitle,
                },
            })
        },
        goHostDetailPage() {
            if (this.dateAndCategory === '마감') {
                this.$toast.error('마감된 이벤트의 프로필은 볼 수 없어요')
                return
            }
            this.$stackRouter.push({
                name: 'SocialMeetProfilePage',
                props: {
                    userId: this.host.id,
                    from: 'social_meeting',
                    status: '',
                    photo: this.host.primary_photo,
                },
            })
        },
        async loadHostBadges() {
            this.allBadges = await badgeService.get(this.post.host_info.id)
            this.summaryBadges = this.allBadges.filter(r => {
                return (r.display_method || '').includes('summary')
            })
            this.summaryBadges.forEach((badge, idx) => {
                if (idx === this.summaryBadges.length - 1) this.badgeTitle += this.summaryBadgeTitle(badge.name)
                else this.badgeTitle += `${this.summaryBadgeTitle(badge.name)}, `
            })
        },
        summaryBadgeTitle(name) {
            return name.replace('인증', '')
        },
        submitToggleInfo({ checked, userId }) {
            if (checked) this.inviteList.push(userId)
            else this.inviteList = this.inviteList.filter(prevUserId => prevUserId !== userId)
        },
        async inviteParticipants() {
            if (!this.activate || !this.activateCheckBox) return

            if (!this.canInvite) {
                this.$toast.error('허용 가능한 인원 수를 초과했어요!')
                return
            }
            try {
                const idx = await this.$modal.basic({
                    body: `<p>참가신청을 수락한 회원들과의</p><p>단톡방을 개설합니다</p>`,
                    buttons: [
                        {
                            label: 'CLOSE',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    const { chat, msg } = await eventService.openGroupChat(this.post.id, this.inviteList)
                    this.$store.commit('inviteParticipantsipants', {
                        post: this.post,
                        inviteList: this.inviteList,
                        chatId: chat.id,
                    })
                    this.inviteList = []
                    this.chatId = chat.id
                    this.$toast.success(msg)
                }
            } catch (e) {
                this.$toast.error(e)
            }
        },
        async goChatRoom(chatId) {
            try {
                this.$loading(true)
                await this.$store.dispatch('loadChats', true)
                const unreadCount = await this.$store.dispatch('loadChat', {
                    chatId: chatId,
                    force: true,
                })
                this.$router.push({
                    name: 'ChatroomPage',
                    params: { unreadCount },
                })
            } catch (e) {
                // 챗아이디 없는 경우
                this.$toast.error('채팅방이 만료되었습니다')
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.padding-horizon {
    padding-left: 16px;
    padding-right: 16px;

    &.group-title {
        display: flex;
        align-items: center;
    }
}
.social-meeting-post-detail {
    overflow-y: auto;

    &.considerInterestedButton {
        $bottom-button-height: 81px;
    }

    .more-padding {
        $bottom-buttom-height: 80px;
        padding-bottom: calc(#{$bottom-buttom-height} + 52px);
    }
}

::v-deep .stack-router-header-bar {
    z-index: 3;
    position: fixed;
    top: 0;
    background-color: transparent;

    .material-icons {
        color: white !important;
    }

    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;

        .material-icons {
            color: $grey-08 !important;
        }
    }
}

.post-detail-photo {
    position: relative;
    width: 100vw;
    height: 100vw;

    .empty-photo {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(315deg, $blue-facebook, #36b3ff);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .empty-member {
            background-color: white;
            border-radius: 8px;
            padding: 2px 8px;
            color: $blue-facebook;
            font-size: 14px;
            @include f-bold;
        }

        .empty-title {
            color: white;
            font-size: 22px;
            text-align: center;
            @include f-medium;
        }
    }

    .background {
        width: 100%;
        height: 100%;
    }
}

.post-detail-content {
    padding: 28px 0;
    line-height: normal;
    letter-spacing: -0.2px;

    .title {
        color: black;
        font-size: 20px;
        max-width: 100%;

        @include f-medium;
    }

    .keywords {
        display: flex;
        flex-flow: row wrap;

        .keyword {
            color: $grey-09;
            font-size: 13px;
            background-color: $grey-02;
            border-radius: 6px;
            padding: 3px 10px;
            @include f-medium;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .line {
        height: 1px;
        background-color: $grey-01;
        margin-top: 24px;
        margin-bottom: 32px;
    }

    .desc {
        line-height: 1.6;
        font-size: 15px;
        color: black;

        @include f-regular;
    }

    .photo {
        height: 100vw;
        width: 100vw;
        margin-left: -16px;
        margin-top: 40px;
    }
}

.post-detail-host,
.post-detail-participants {
    line-height: normal;
    letter-spacing: -0.2px;

    .group-event {
        border-top: 1px solid $grey-02;
        padding: 40px 16px 0 16px;
    }

    .friend-event {
        @extend .group-event;
    }

    .title-wrapper {
        @include items-center;
        @include flex-between;

        .invite-btn {
            background: $grey-02;
            color: $grey-05;
            padding: 8px 16px;
            white-space: nowrap;
            border-radius: 8px;

            &.activate {
                background: $blue-facebook;
                color: white;
            }
        }
    }

    .title {
        color: black;
        font-weight: 500;
        font-size: 18px;

        @include f-medium;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 12px;
    }

    .no-cards {
        color: $grey-08;
        font-size: 14px;
    }
}

.social-meeting-manual {
    margin: 12px 16px 16px 16px;
    padding: 16px 14px;
    border-radius: 10px;
    background-color: $grey-01;

    .manual {
        display: flex;
        margin-top: 10px;

        .dot {
            width: 4px;
            height: 4px;
            background-color: $grey-07;
            border-radius: 2px;
            margin-top: 7px;
            margin-right: 12px;
        }

        .text {
            font-size: 12px;
            color: black;

            ::v-deep span {
                @include f-bold;
                color: $blue-facebook;
            }
        }
    }
}

.bottom-button {
    position: fixed;
}
::v-deep button {
    &.disabled {
        @include f-regular;
    }
}
.anonymous-host {
    z-index: 2;
    padding-bottom: 28px;
    margin-top: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    border-bottom: 1px solid $grey-02;
    @include flex-row;
    @include f-medium;

    .gender-photo {
        height: 24px;
        width: 24px;
        border-radius: 40%;
        border: 1px solid rgba(233, 233, 237, 0.7);

        .blind-photo {
            height: 12px;
            width: 12px;
        }
        .mini-photo {
            @extend .gender-photo;
        }
        @include center;
    }
    .host {
        .nickname {
            color: black;
            font-size: 14px;
            @include f-regular;
        }
        .age-dot {
            color: $grey-07;
        }
    }
}
.verify {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;

    i {
        font-size: 18px;
        margin-left: 3px;
    }
    .verify-info {
        @include f-bold;
        text-decoration: underline;
        margin-right: 3px;
    }
}
.verified-host {
    display: flex;
    align-items: center;
    margin-top: 28px;
    padding-top: 32px;
    border-top: 1px solid $grey-02;

    .host {
        .nickname {
            color: black;
            font-size: 16px;
            @include f-regular;
        }
        .age-dot {
            color: $grey-07;
        }
    }
}

.intro-container {
    padding: 0 16px;

    .host-intro {
        width: 100%;
        background: $grey-02;
        padding: 16px;
        margin-top: 20px;
        color: black;
        font-size: 15px;
        border-radius: 0 16px 16px 16px;
    }
}

.meeting-infos {
    margin: 28px 16px 8px 16px;
    border-bottom: 1px solid $grey-02;

    .info {
        margin-bottom: 20px;
        font-size: 14px;
        @include f-medium;
        color: $grey-09;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}
.content {
    padding: 28px 16px 32px 16px;
}
.map-container {
    border-radius: 10px;
    border: 1px solid $grey-03;

    .map-img {
        width: 100%;
        height: 220px;
        border-radius: 10px 10px 0 0;
    }

    .placeinfo {
        padding: 14px;

        .placename {
            color: black;
            font-size: 16px;
            margin-bottom: 4px;
            @include f-medium;
        }
        .address {
            color: $grey-08;
            font-size: 13px;
        }
    }
}
</style>
