<template>
    <div class="card" :class="{ 'card-flipped': onFlip }">
        <div class="card-inner">
            <div v-if="showCard" class="social-meeting-profile" @click="goToUserDetail">
                <div class="img-wrapper">
                    <img :src="photoUrls[0]" @error="$event.target.src = $blankProfile" />
                    <div class="nickname-status c-white">
                        <p class="p-l-8 nickname f-medium f-15" :class="{ 'm-b-8': !statusMessage }">{{ nickname }}</p>
                        <p class="p-l-8 f-bold c-facebook f-12 m-b-4">{{ statusMessage }}</p>
                    </div>
                </div>
                <div class="social-meeting-profile-button-wrapper">
                    <button
                        class="btn btn-facebook"
                        :class="{ accept: status === 'accepted', contact: isPaid }"
                        @click.stop="onClickButton"
                    >
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
            <div v-if="!showCard" class="social-meeting-card" @click="flipCard">
                <div class="social-meeting-card-inside center" :class="[gender === 0 ? 'male' : 'female']">
                    <img :src="logo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import eventService from '@/services/event'
import userService from '@/services/user'

export default {
    name: 'SocialMeetingCardForFriend',
    data: () => ({
        onFlip: false,
        status: null,
        forceShowContactLabel: false,
        name: '',
    }),
    props: {
        card: {
            type: Object,
            required: false,
        },
        me: {
            type: String,
        },
    },
    computed: {
        showCard() {
            if (this.me === 'host') {
                return this.card.status !== 'request' || this.status !== 'request'
            }

            if (this.me === 'participant') {
                return this.card.status === 'accepted'
            }

            return false
        },
        user() {
            if (this.me === 'host') return this.card.participant_info
            else return this.card.host_info
        },
        gender() {
            return this.user.gender
        },
        logo() {
            return this.gender === 0
                ? require('@/assets/images/logo_male.svg')
                : require('@/assets/images/logo_female.svg')
        },
        nickname() {
            if (this.bothPaid && this.user.name) {
                return `${this.name}(${this.user.nickname})`
            } else {
                return this.user.nickname
            }
        },
        photoUrls() {
            return this.user.photo_urls || []
        },
        statusMessage() {
            if (this.card.host_paid && this.card.participant_paid) {
                return '연락처가 교환되었어요!'
            } else {
                if (
                    (this.me === 'host' && this.card.participant_paid) ||
                    (this.me === 'participant' && this.card.host_paid)
                ) {
                    return '상대는 이미 수락했어요!'
                }
            }

            return ''
        },
        buttonLabel() {
            if (this.status === 'checked') {
                return '관심 수락하기'
            } else if (this.status === 'accepted') {
                if (this.isPaid) {
                    return '연락처 보기'
                }

                return '만남 수락하기'
            }

            return ''
        },
        isPaid() {
            let isPaid

            if (this.me === 'host') {
                isPaid = this.card.host_paid
            } else if (this.me === 'participant') {
                isPaid = this.card.participant_paid
            }

            return isPaid || this.forceShowContactLabel
        },
        bothPaid() {
            return this.card.host_paid && this.card.participant_paid
        },
        success() {
            return this.card.host_paid && this.card.participant_paid
        },
    },
    methods: {
        async flipCard() {
            try {
                // 프로필 오픈
                if (this.checkVanilla('openProfile')) {
                    await eventService.update(this.card.event_id, this.card.id, 'check')

                    this.card.status = 'checked'
                    this.card.participant_checked = false

                    this.$store.dispatch('loadBadges')
                    this.onFlip = true

                    setTimeout(() => {
                        this.status = 'checked'
                        this.onFlip = false
                    }, 100)

                    this.$store.commit('updateCardStateForFriend', {
                        status: 'request',
                        eventId: this.card.event_id,
                        id: this.card.id,
                    })
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        initStatus() {
            this.status = this.card.status
            this.name = this.$nameOrNick(this.user)
        },
        async onClickButton() {
            try {
                if (this.status === 'checked' && this.me === 'host') {
                    const idx = await this.$modal.basic({
                        body:
                            '상대가 내 프로필을 확인할 수 있습니다. 이후 양쪽 모두 만남을 수락하면 연락처가 교환됩니다.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (idx === 1) {
                        const { msg } = await eventService.update(this.card.event_id, this.card.id, 'accept')

                        // await this.$store.dispatch('updateAllMyPosts')
                        this.$store.commit('updateCardStateForFriend', {
                            status: 'checked',
                            eventId: this.card.event_id,
                            id: this.card.id,
                        })
                        this.status = 'accepted'
                        this.$toast.success(msg)
                    }
                    return
                }

                if (this.status === 'accepted') {
                    if (this.isPaid) {
                        const { key, msg } = await eventService.success(this.card.event_id, this.card.id)
                        if (key === 'success') {
                            this.$modal.custom({
                                component: 'ModalContact',
                                options: { body: msg },
                            })
                        } else if (key === 'failed') {
                            this.$toast.error(msg)
                        }
                    } else {
                        const { vanilla } = this.$store.getters.settings
                        const modalBody = `바닐라 ${vanilla.accept_meeting}개를 사용해 만남을 수락합니다. ${
                            (this.me === 'host' ? this.card.participant_paid : this.card.host_paid)
                                ? '상대가 이미 수락하여, 내가 수락하면 바로 연락처가 교환됩니다.'
                                : '이후 상대도 만남을 수락하면 연락처가 교환됩니다'
                        }`
                        const idx = await this.$modal.basic({
                            title: '만남 수락',
                            body: modalBody,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1 && this.checkVanilla('accepted')) {
                            await eventService.update(
                                this.card.event_id,
                                this.card.id,
                                this.me === 'host' ? 'host_paid' : 'participant_paid',
                            )
                            this.forceShowContactLabel = true
                            this.getName()
                            this.$store.dispatch('loadBadges')
                            const bothPaid = this.me === 'host' ? this.card.participant_paid : this.card.host_paid

                            if (bothPaid) {
                                this.$toast.success('연락처가 교환되었어요!')
                            } else {
                                this.$toast.success(
                                    "상대가 만남을 수락하면 알림을 드려요!\n'프로필>설정'에서 알림을 켤 수 있어요!",
                                )
                            }

                            try {
                                this.$store.commit('updateCardStateForFriend', {
                                    me: this.me,
                                    status: 'accepted',
                                    eventId: this.card.event_id,
                                    id: this.card.id,
                                })
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        return
                    }
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        goToUserDetail() {
            const photos = this.photoUrls.map(p => ({ url: p }))
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.user.id,
                    photos: photos,
                    from: 'TodayMeetingCard',
                },
            })
        },
        checkVanilla(status) {
            const { vanilla: currentVanilla } = this.$store.getters.badges
            const { vanilla } = this.$store.getters.settings

            let lackOfVanilla = false
            let insufficientVanilla = 0

            if (this.me === 'host') {
                if (status === 'openProfile') {
                    if (currentVanilla < vanilla.open_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.open_meeting - currentVanilla
                    } else {
                        this.$toast.success(`바닐라 ${vanilla.open_meeting}개를 사용하여 프로필을 오픈했어요`)

                        return true
                    }
                } else if (status === 'accepted') {
                    if (currentVanilla < vanilla.accept_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.accept_meeting - currentVanilla
                    } else {
                        return true
                    }
                }
            } else if (this.me === 'participant') {
                if (status === 'openProfile') return true
                if (status === 'accepted') {
                    if (currentVanilla < vanilla.accept_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.accept_meeting - currentVanilla
                    } else {
                        return true
                    }
                }
            }

            if (lackOfVanilla) {
                this.$modal
                    .basic({
                        body: `바닐라 ${insufficientVanilla}개가 부족합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({ name: 'InAppStorePage' })
                        }
                    })
            }
        },
        async getName() {
            const user = await userService.detail(this.user.id)
            this.name = user.name
        },
    },
    mounted() {
        if (this.isPaid) {
            this.getName()
        }
        this.initStatus()
    },
}
</script>
<style lang="scss" scoped>
$column-gap: 12px;
$side-padding: 16px;
$card-width: calc((100vw - #{$side-padding} * 2 - #{$column-gap}) / 2);
$card-height: calc(#{$card-width} * 1.27);

.card,
.card-inner {
    width: $card-width;
    height: $card-height;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.card {
    perspective: 2000px;
    transition: transform 1s;
    box-shadow: none;

    &.card-flipped {
        .card-inner {
            transform: rotateY(180deg);
        }
    }
}

.social-meeting-profile,
.social-meeting-card {
    width: 100%;
    height: 100%;
    background-color: $grey-09;
}

.social-meeting-card {
    transform: rotateY(180deg);
    padding: 4px;
    border-radius: 10px;

    &-inside {
        height: 100%;
        width: 100%;
        border-radius: 6px;

        &.male {
            border: 1px solid $blue-man;
        }

        &.female {
            border: 1px solid $pink-woman;
        }
    }
}

.social-meeting-profile {
    @include flex;

    .img-wrapper {
        width: $card-width;
        height: $card-width;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .nickname-status {
            width: 100%;
            height: 48px;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(67, 66, 69, 0), $grey-09 96%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include flex;
        }

        .nickname {
            font-weight: 500;
        }
    }

    &-button-wrapper {
        padding: 8px;
        padding-top: 0;
        height: calc(#{$card-height} - #{$card-width});

        @include center;

        button {
            width: 100%;
            height: 36px;
            font-size: 14px;
            font-weight: 500;

            &.accept {
                background-color: $pink-deep;
                color: white;
            }

            &.contact {
                background-color: white;
                color: black;
            }
        }
    }
}
</style>
