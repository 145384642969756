<template>
    <div class="hostmeet-container m-b-28">
        <div class="hostmeet-card" :class="{ 'card-flipped': onFlip }">
            <div v-if="!showCard" class="unflipped" @click="flipCard">
                <div class="card-inner">
                    <div class="social-meeting-card">
                        <div class="social-meeting-card-inside center" :class="[gender === 0 ? 'male' : 'female']">
                            <img :src="logo" alt="" />
                        </div>
                    </div>
                </div>
                <div class="text-wrapper">
                    <div class="flip-card" v-html="`프로필을 확인해주세요`" />
                    <div>{{ this.gender === 0 ? '남성' : '여성' }}</div>
                </div>
            </div>
            <div v-else class="flipped">
                <div class="photo-info" @click="goParticipantProfile">
                    <div class="card-inner">
                        <div class="social-meeting-profile">
                            <img class="img-wrapper" :class="{ leaved: status === 'leaved' }" :src="photoUrls[0]" />
                        </div>
                    </div>
                    <div class="profile-info">
                        <div class="nickname-gender">
                            <span class="m-r-8 f-16 f-medium c-black">{{ user.nickname }}</span>
                            <div :class="[user.gender === 0 ? 'bg-male' : 'bg-female']">
                                <img class="mini-photo" :src="require('@/assets/images/person.svg')" />
                            </div>
                        </div>
                        <p class="f-14" v-html="profileInfo" />
                    </div>
                </div>
                <Checkbox v-if="status === 'checked' && activateCheckBox" v-model="checked" />
                <span
                    v-if="status !== 'request' && status !== 'checked'"
                    :class="status === 'accepted' ? 'c-facebook' : 'c-grey-05'"
                    class="status f-bold f-12"
                >
                    {{ statusMsg }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import eventService from '@/services/event'

export default {
    name: 'SocialMeetingCard',
    data: () => ({
        onFlip: false,
        forceShowContactLabel: false,
        name: '',
        checked: false,
    }),
    watch: {
        checked: function () {
            this.toggleInvite()
        },
    },
    props: {
        card: {
            type: Object,
            required: false,
        },
        me: {
            type: String,
        },
        activateCheckBox: {
            type: Boolean,
        },
    },
    computed: {
        status() {
            return this.card.status
        },
        statusMsg() {
            if (this.status === 'accepted') return '초대 완료'
            else if (this.status === 'leaved') return '나간 회원'
            else return ''
        },
        showCard() {
            return this.status !== 'request'
            // return false
        },
        user() {
            if (this.me === 'host') return this.card.participant_info
            else return this.card.host_info
        },
        gender() {
            return this.user.gender
        },
        logo() {
            return this.gender === 0
                ? require('@/assets/images/logo_male.svg')
                : require('@/assets/images/logo_female.svg')
        },
        nickname() {
            if (this.isPaid) {
                return `${this.name}(${this.user.nickname})`
            } else {
                return this.user.nickname
            }
        },
        profileInfo() {
            return `${this.user.age}세<span style="color: #C8C8CD">ㅣ</span>${this.user.job_name}`
        },
        photoUrls() {
            return this.card.participant_info.photo_urls || []
        },
        statusMessage() {
            if (this.card.host_paid && this.card.participant_paid) {
                return '연락처가 교환되었어요!'
            } else {
                if (
                    (this.me === 'host' && this.card.participant_paid) ||
                    (this.me === 'participant' && this.card.host_paid)
                ) {
                    return '상대는 이미 수락했어요!'
                }
            }

            return ''
        },
        buttonLabel() {
            if (this.status === 'checked') {
                return '관심 수락하기'
            } else if (this.status === 'accepted') {
                if (this.isPaid) {
                    return '연락처 보기'
                }

                return '만남 수락하기'
            }

            return ''
        },
        isPaid() {
            let isPaid

            if (this.me === 'host') {
                isPaid = this.card.host_paid
            } else if (this.me === 'participant') {
                isPaid = this.card.participant_paid
            }

            return isPaid || this.forceShowContactLabel
        },
        success() {
            return this.card.host_paid && this.card.participant_paid
        },
    },
    methods: {
        async flipCard() {
            try {
                await eventService.update(this.card.event_id, this.card.id, 'check')
                this.onFlip = true

                setTimeout(() => {
                    this.status = 'checked'
                    this.onFlip = false
                }, 100)

                this.goParticipantProfile()
                this.$store.commit('updateReq2Check', {
                    eventId: this.card.event_id,
                    id: this.card.id,
                })
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        toggleInvite() {
            this.$emit('toggle', { checked: this.checked, userId: this.card.id })
        },
        goParticipantProfile() {
            if (this.status === 'leaved') {
                this.$toast.error('모임 단톡방에서 나간 회원이에요')
                return
            }
            this.$stackRouter.push({
                name: 'SocialMeetProfilePage',
                props: {
                    userId: this.user.id,
                    from: 'social_meeting',
                    photo: this.photoUrls[0],
                    status: '',
                },
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.unflipped {
    display: flex;
    align-items: center;
    width: 100%;
}
.flipped {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .photo-info {
        display: flex;
        align-items: center;

        .bg-male,
        .bg-female {
            width: 16px;
            height: 16px;
            border-radius: 6.4px;
            //padding: 5px;
            @include center;

            .mini-photo {
                width: 10px;
                height: 10px;
            }
        }
    }
    .status {
        padding-top: 5px;
    }
}
$column-gap: 12px;
$side-padding: 16px;
$card-width: calc((100vw - #{$side-padding} * 4 - #{$column-gap}) / 4);
$card-height: calc(#{$card-width} * 1);

.card,
.card-inner {
    width: $card-width;
    height: $card-width;
    margin-right: 16px;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.profile-info {
    height: 100%;
    padding-top: 5px;

    .nickname-gender {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
    }
}
.text-wrapper {
    .flip-card {
        color: black;
        font-size: 16px;
        margin-bottom: 6px;
        @include f-medium;
    }
}

.social-meeting-profile,
.social-meeting-card {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: $grey-09;
}

.social-meeting-card {
    padding: 4px;
    border-radius: 10px;

    &-inside {
        height: 100%;
        width: 100%;
        border-radius: 6px;

        img {
            width: 70%;
            height: 70%;
        }
        &.male {
            border: 1px solid $blue-man;
        }

        &.female {
            border: 1px solid $pink-woman;
        }
    }
}

.social-meeting-profile {
    @include flex;

    .img-wrapper {
        width: $card-width;
        height: $card-width;
        position: relative;
        border-radius: 8px;

        &.leaved {
            opacity: 0.4;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .nickname-status {
            width: 100%;
            height: 48px;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(67, 66, 69, 0), $grey-09 96%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include flex;
        }

        .nickname {
            font-weight: 500;
        }
    }
}
::v-deep.checkbox {
    &.checked {
        background-color: $blue-facebook !important;
    }
}
</style>
